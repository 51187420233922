export const environment = {
  production: true,
  name: 'prod',
  useEmulators: false,
  firebase: {
    config: {
      apiKey: 'AIzaSyBDtxN2JZ6JX-fN_LiJK09SGE4YW4ffijk',
      authDomain: 'store-bloc-uat.firebaseapp.com',
      projectId: 'store-bloc-uat',
      storageBucket: 'store-bloc-uat.appspot.com',
      messagingSenderId: '823690244780',
      appId: '1:823690244780:web:a7d117d0f2ae9398256509',
      measurementId: 'G-FQ5MZ8617R'
    },
    verificationRedirectUrl: 'https://store-bloc-customer-uat.web.app/'
  },
  region: 'asia-southeast1',
  recaptchaKey: '6LeWG3UoAAAAABlWuxAUGMc8IYe3Lq6RUxfZWNqY',
  swiftPayRedirectUrl: 'https://api.pay.sandbox.live.swiftpay.ph',
  buildNumber: '20241009.3',
  appUrl: 'https://store-bloc-merchant-uat.web.app/',
  apiUrl: 'https://asia-southeast1-store-bloc-uat.cloudfunctions.net/api'
};
